/* BlogCard.css */

@keyframes moveGradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.gradient-bg {
    width: 100%;
    height: 200px; /* Adjust this value to the desired height */
    background-size: 200% 200%;
    /* Add other styles as needed */
}

.card {
    /* Other existing styles */
    overflow: hidden; /* To make sure the gradient div doesn't overflow */
}

.rounded-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.glow-button {
    transition: box-shadow 0.3s ease;
}

.glow-button:hover {
    animation: glow 5s infinite;
}

@keyframes glow {
    0% {
        box-shadow: 0 0 15px #ffb0b0;
    }
    25% {
        box-shadow: 0 0 15px #ffe3b1;
    }
    50% {
        box-shadow: 0 0 15px #ffff98;
    }
    75% {
        box-shadow: 0 0 15px #a8ffa8;
    }
    100% {
        box-shadow: 0 0 15px #ababff;
    }
}

.blog-cover-front-0 {
    font-family: "Press Start 2P", system-ui;
    font-weight: 400;
    font-style: normal;
    text-wrap: wrap !important;
    white-space: wrap !important;
}

.blog-cover-front-1 {
    font-family: "Monoton", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-wrap: wrap !important;
    white-space: wrap !important;
}

.blog-cover-front-2 {
    font-family: "Black Ops One", system-ui;
    font-weight: 400;
    font-style: normal;
    text-wrap: wrap !important;
    white-space: wrap !important;
}

.blog-cover-front-3 {
    font-family: "Bungee Shade", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-wrap: wrap !important;
    white-space: wrap !important;
}



