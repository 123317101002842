.hamburger {
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible; }
.hamburger:hover {
    opacity: 0.8; }
.hamburger.is-active:hover {
    opacity: 0.8; }
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: #fff; }

.hamburger-box {
    width: 30px;
    height: 18px;
    display: inline-block;
    position: relative; }

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; }
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
    height: 3px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
.hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
.hamburger-inner::before {
    top: -8px; }
.hamburger-inner::after {
    bottom: -8px; }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
    top: 2px;
    transition: background-color 0s 0.13s linear; }
.hamburger--spring .hamburger-inner::before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
    transition-delay: 0.22s;
    background-color: transparent !important; }
.hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg); }
.hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg); }

#navbar-bg {
    background: rgba(33, 33, 41, 0.45);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    box-shadow: 7px 3px 7px 4px rgb(0 0 0 / 50%);
    border-radius: 2rem;
    top: 0.5em;
}

@media (min-width: 992px) {
    #navbar-bg a:hover {
        transform: scale(1.1);
        transition: .2s ease-in;
    }
}
