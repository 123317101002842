.hr {
    width: 100%;
    height: 1px;
    display: block;
    position: relative;
    margin-bottom: 0;
    margin-top: 1em;
    padding: .5em 0;
}

.hr:after, .hr:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 50%;
    left: 0;
}

.green:before {
    background: linear-gradient(90deg, #50A385 0%, #50A385 50%, transparent 50%, transparent 100%);
}

.green.anim:before {
    background: linear-gradient(90deg, #50A385 0%, #50A385 5%, transparent 5%, transparent 10%, #50A385 10%, #50A385 15%, transparent 15%, transparent 20%, #50A385 20%, #50A385 25%, transparent 25%, transparent 30%, #50A385 30%, #50A385 35%, transparent 35%, transparent 40%, #50A385 40%, #50A385 45%, transparent 45%, transparent 50%, #50A385 50%, #50A385 55%, transparent 55%, transparent 60%, #50A385 60%, #50A385 65%, transparent 65%, transparent 70%, #50A385 70%, #50A385 75%, transparent 75%, transparent 80%, #50A385 80%, #50A385 85%, transparent 85%, transparent 90%, #50A385 90%, #50A385 95%, transparent 95%, transparent 100%);
}

.lime:before {
    background: linear-gradient(90deg, #86A338 0%, #86A338 50%, transparent 50%, transparent 100%);
}

.lime.anim:before {
    background: linear-gradient(90deg, #86A338 0%, #86A338 5%, transparent 5%, transparent 10%, #86A338 10%, #86A338 15%, transparent 15%, transparent 20%, #86A338 20%, #86A338 25%, transparent 25%, transparent 30%, #86A338 30%, #86A338 35%, transparent 35%, transparent 40%, #86A338 40%, #86A338 45%, transparent 45%, transparent 50%, #86A338 50%, #86A338 55%, transparent 55%, transparent 60%, #86A338 60%, #86A338 65%, transparent 65%, transparent 70%, #86A338 70%, #86A338 75%, transparent 75%, transparent 80%, #86A338 80%, #86A338 85%, transparent 85%, transparent 90%, #86A338 90%, #86A338 95%, transparent 95%, transparent 100%);
}

.red:before {
    background: linear-gradient(90deg, #A72D39 0%, #A72D39 50%, transparent 50%, transparent 100%);
}

.red.anim:before {
    background: linear-gradient(90deg, #A72D39 0%, #A72D39 5%, transparent 5%, transparent 10%, #A72D39 10%, #A72D39 15%, transparent 15%, transparent 20%, #A72D39 20%, #A72D39 25%, transparent 25%, transparent 30%, #A72D39 30%, #A72D39 35%, transparent 35%, transparent 40%, #A72D39 40%, #A72D39 45%, transparent 45%, transparent 50%, #A72D39 50%, #A72D39 55%, transparent 55%, transparent 60%, #A72D39 60%, #A72D39 65%, transparent 65%, transparent 70%, #A72D39 70%, #A72D39 75%, transparent 75%, transparent 80%, #A72D39 80%, #A72D39 85%, transparent 85%, transparent 90%, #A72D39 90%, #A72D39 95%, transparent 95%, transparent 100%);
}

.orange:before {
    background: linear-gradient(90deg, #A95626 0%, #A95626 50%, transparent 50%, transparent 100%);
}

.orange.anim:before {
    background: linear-gradient(90deg, #A95626 0%, #A95626 5%, transparent 5%, transparent 10%, #A95626 10%, #A95626 15%, transparent 15%, transparent 20%, #A95626 20%, #A95626 25%, transparent 25%, transparent 30%, #A95626 30%, #A95626 35%, transparent 35%, transparent 40%, #A95626 40%, #A95626 45%, transparent 45%, transparent 50%, #A95626 50%, #A95626 55%, transparent 55%, transparent 60%, #A95626 60%, #A95626 65%, transparent 65%, transparent 70%, #A95626 70%, #A95626 75%, transparent 75%, transparent 80%, #A95626 80%, #A95626 85%, transparent 85%, transparent 90%, #A95626 90%, #A95626 95%, transparent 95%, transparent 100%);
}

.gray:before {
    background: linear-gradient(90deg, #252527 0%, #252527 50%, transparent 50%, transparent 100%);
}

.gray.anim:before {
    background: linear-gradient(90deg, #252527 0%, #252527 5%, transparent 5%, transparent 10%, #252527 10%, #252527 15%, transparent 15%, transparent 20%, #252527 20%, #252527 25%, transparent 25%, transparent 30%, #252527 30%, #252527 35%, transparent 35%, transparent 40%, #252527 40%, #252527 45%, transparent 45%, transparent 50%, #252527 50%, #252527 55%, transparent 55%, transparent 60%, #252527 60%, #252527 65%, transparent 65%, transparent 70%, #252527 70%, #252527 75%, transparent 75%, transparent 80%, #252527 80%, #252527 85%, transparent 85%, transparent 90%, #252527 90%, #252527 95%, transparent 95%, transparent 100%);
}

.hr:before {
    background-size: 15px;
    background-position: center;
    z-index: 1;
}

.hr:after {
    transition: opacity 0.3s ease, animation 0.3s ease;
    background-size: 200%;
    background: linear-gradient(to right, #62efab 5%, #f2ea7d 15%, #f2ea7d 25%, #ff8797 35%, #ff8797 45%, #e1a4f4 55%, #e1a4f4 65%, #82fff4 75%, #82fff4 85%, #62efab 95%) 0;
    animation: bar 15s linear infinite;
}

@keyframes bar {
    0% {
        background-position: 0;
    }
    100% {
        background-position: 200%;
    }
}

.hr.anim:before {
    background-size: 150px;
    background-position: center;
    z-index: 1;
    animation: bar 60s linear infinite;
}

.hr.anim:hover:before {
    animation-duration: 20s;
}

.hr.anim:hover:after {
    animation-duration: 2s;
}