body {
    font-family: "Onest", sans-serif !important;
    font-optical-sizing: auto;
    font-style: normal;
    background: linear-gradient(270deg,
    #121212,
    #1e0404,
    #13031e,
    #010c00,
    #1e1001,
    #121212
    );
    background-size: 300% 100%;
    -webkit-animation: AnimationName 15s linear infinite;
    -moz-animation: AnimationName 15s linear infinite;
    animation: AnimationName 15s linear infinite;
}


@-webkit-keyframes AnimationName {
    0% {
        background-position: 0 80%
    }
    50% {
        background-position: 100% 10%
    }
    100% {
        background-position: 0 80%
    }
}

@-moz-keyframes AnimationName {
    0% {
        background-position: 0 80%
    }
    50% {
        background-position: 100% 10%
    }
    100% {
        background-position: 0 80%
    }
}

@keyframes AnimationName {
    0% {
        background-position: 0 80%
    }
    50% {
        background-position: 100% 10%
    }
    100% {
        background-position: 0 80%
    }
}

section {
    margin-bottom: 6em;
}

a:focus, span:focus, button:focus {
    outline: none;
}

.dark-shadow {
    box-shadow: 7px 8px 29px 0 rgba(0, 0, 0, 1);
}


#particle-bg {
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-size: contain;
}


.dark-bg {
    border-radius: 1.2rem;
    padding: 1px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    background-color: rgba(33, 33, 33, 0.3);
    box-shadow: rgb(0 0 0 / 80%) 6px 4px 9px 3px,
    inset 1px 1px 0 0 rgba(148, 148, 148, 0.03),
    inset 0 0 20px rgba(148, 148, 148, 0.05)
}

.dark-bg::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: linear-gradient(
            125deg,
            rgba(255, 255, 255, 0.05) 0%,
            rgba(255, 255, 255, 0.02) 40%,
            rgba(0, 0, 0, 0.1) 80%
    );
    z-index: -1;
    pointer-events: none;
}

.blur-background {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.rounded-all {
    border-radius: 1.2em;
}

.rounded-top {
    border-top-left-radius: 1.2rem !important;
    border-top-right-radius: 1.2rem !important;
}

.gradient-text-1 {
    background: #16BFFD; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #CB3066, #16BFFD); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #CB3066, #16BFFD); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-text-2 {
    background: #EECDA3; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #EF629F, #EECDA3); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #EF629F, #EECDA3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-text-3 {
    background: #FF8008; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FFC837, #FF8008); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FFC837, #FF8008); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-text-4 {
    background: #1FA2FF; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #A6FFCB, #12D8FA, #1FA2FF); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #A6FFCB, #12D8FA, #1FA2FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-text-5 {
    background: #ef32d9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #89fffd, #ef32d9); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #89fffd, #ef32d9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.swiper-container {
    width: 480px;
}

@media screen and (min-width: 640px) {
    .swiper-container {
        width: 640px;
    }
}

@media screen and (min-width: 768px) {
    .swiper-container {
        width: 768px;
    }
}

canvas#fluid {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    pointer-events: none;
    opacity: 40%;
}


